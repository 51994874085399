// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-underscore-dangle */
import { ComponentProps, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NetworkStatus } from '@apollo/client'
import { MarketplacePage as MarketplaceScreenPage } from '@kickjobs/search-marketplace-module'
import { MarketplaceFormMethods } from '@kickjobs/search-marketplace-module/dist/pages/MarketplacePage/MarketplaceProvider'

import {
  PublicProjectsOrderBy,
  PublicStudentsOrderBy,
  PublicStudentsOrderByColumn,
  usePublicProjectsQuery,
  usePublicStudentsQuery,
} from '@/apollo/generated/graphql'
import { GetEntityType } from '@/containers/DataTable/types'
import { usePrevious } from '@/hooks/usePrevious'
import MetaTagsProvider from '@/layouts/provider/MetaTagsProvider'
import { objectToUrlSearchParams } from '@/utils/routing'

import useTranslatedSearchLayoutStructure from '../staticData'

import { getSearchFilters } from './parseParamsSchema'

type MarketplacePageProps = ComponentProps<typeof MarketplaceScreenPage>

type Marketplace = MarketplacePageProps['marketplace']

const defaultValues: Marketplace['filters']['defaultValues'] = {
  type: 'students',
  categories: [],
  skills: [],
  specializations: [],
  range: null,
  countries: [],
}

const initialFiltersValues = getSearchFilters(new URLSearchParams(window.location.search))

export const MarketplacePage = () => {
  const [t] = useTranslation()

  const { navigation, footer, footerBottom } = useTranslatedSearchLayoutStructure()

  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [data, setData] = useState<Marketplace['data']>([])

  const [filtersValues, setFiltersValues] = useState<Marketplace['filters']['defaultValues']>(
    initialFiltersValues as unknown as Marketplace['filters']['defaultValues'],
  )

  const previousFiltersValues = usePrevious(filtersValues)

  const [orderBy, setOrderBy] = useState<PublicStudentsOrderBy | PublicProjectsOrderBy>({
    column: PublicStudentsOrderByColumn.CreatedAt,
    direction: initialFiltersValues.orderBy as PublicStudentsOrderBy['direction'],
  })

  const createProjectsData = (entities: GetEntityType<typeof usePublicProjectsQuery>[]) =>
    entities.map(({ id, title, images, duration_days, amount, user }) => ({
      coverImage: images?.length ? `${images[0].path}` : '',
      avatar: `${user?.avatar}`,
      title: `${title}`,
      price: `${Math.round(amount)}`,
      delivery: `${duration_days}`,
      ownerName: `${user?.firstName} ${user?.lastName}`,
      rate: `${user?.star_rating}`,
      totalRates: `${user?.received_reviews?.paginatorInfo.total}`,
      location: 'Netherlands',
      link: `/marketplace/${user?.publicId}/${encodeURIComponent(title?.replace(/\s/g, ''))}-${id}`,
    })) as Marketplace['data']

  const createStudentsData = (entities: GetEntityType<typeof usePublicStudentsQuery>[]) =>
    entities.map(
      ({
        publicId,
        avatar,
        jobCategories,
        skills,
        firstName,
        lastName,
        about_me,
        received_reviews,
        star_rating,
      }) => ({
        avatar: avatar || '',
        category: jobCategories?.length ? jobCategories[0].name : '',
        title: jobCategories?.find((cat) => cat.is_main)?.name,
        company: `${firstName as string} ${lastName as string}`,
        categories: skills?.map((skill) => skill.skill.name) || [],
        price: `${
          about_me?.desired_hourly_rate ? Math.round(about_me?.desired_hourly_rate) : '30'
        }`,
        location: 'Netherlands',
        link: publicId ? `/marketplace/${publicId}` : '#',
        rate: star_rating,
        totalRates: received_reviews?.paginatorInfo.total,
        wageType: 'hourly_rate',
      }),
    ) as Marketplace['data']

  const studentsQuery = usePublicStudentsQuery({
    variables: {
      first: 9,
      page: currentPage,
      categories: filtersValues.categories,
      skills: filtersValues.skills,
      countries: filtersValues?.countries,
      hourlyRate: filtersValues?.range
        ? {
            min:
              typeof filtersValues.range[0] === 'string'
                ? parseFloat(filtersValues.range[0])
                : filtersValues.range[0],
            max:
              typeof filtersValues.range[1] === 'string'
                ? parseFloat(filtersValues.range[1])
                : filtersValues.range[1],
          }
        : undefined,
      orderBy: orderBy as PublicStudentsOrderBy,
    },
    fetchPolicy: 'network-only',
    skip: filtersValues.type !== 'students',
    onCompleted: (result) => {
      setIsLoadingMore(false)
      setData(
        (prevState) =>
          [
            ...prevState,
            ...createStudentsData(result.publicStudents.data || []),
          ] as Marketplace['data'],
      )
    },
  })

  const projectsQuery = usePublicProjectsQuery({
    variables: {
      first: 9,
      page: currentPage,
      categoryId: filtersValues.categories,
      specializations: filtersValues.specializations,
      wageRanges:
        filtersValues?.range && filtersValues?.range?.length
          ? {
              from:
                typeof filtersValues.range[0] === 'string'
                  ? parseFloat(filtersValues.range[0])
                  : filtersValues.range[0],
              to:
                typeof filtersValues.range[1] === 'string'
                  ? parseFloat(filtersValues.range[1])
                  : filtersValues.range[1],
            }
          : undefined,
      orderBy: orderBy as PublicProjectsOrderBy,
    },
    fetchPolicy: 'network-only',
    skip: filtersValues.type !== 'projects',
    onCompleted: (result) => {
      setIsLoadingMore(false)
      setData(
        (prevState) =>
          [
            ...prevState,
            ...createProjectsData(result.publicProjects.data || []),
          ] as Marketplace['data'],
      )
    },
  })

  const publicStudents =
    studentsQuery.data?.publicStudents || studentsQuery?.previousData?.publicStudents
  const publicStudentsNetworkStatus = studentsQuery.networkStatus
  const publicPorjects =
    projectsQuery.data?.publicProjects || projectsQuery?.previousData?.publicProjects
  const publicProjectsNetworkStatus = projectsQuery.networkStatus
  const totalItems =
    filtersValues.type !== 'projects'
      ? publicStudents?.paginatorInfo.total
      : publicPorjects?.paginatorInfo.total

  const createSettings = (entityType: string) => {
    if (entityType === 'students') {
      return {
        categories: publicStudents?.filters.categories
          ? [...publicStudents.filters.categories].sort((a, b) => a.label.localeCompare(b.label))
          : [],
        specializations: [],
        skills: publicStudents?.filters.skills
          ? [...publicStudents.filters.skills].sort((a, b) => a.label.localeCompare(b.label))
          : [],
        countries: publicStudents?.filters.countries || [],
        range: { min: publicStudents?.filters.wages.min, max: publicStudents?.filters.wages.max },
      } as Marketplace['filters']['settings']
    }
    return {
      categories: publicPorjects?.filters.categories
        ? publicPorjects?.filters.categories
            ?.map(({ name, id }) => ({ label: name, id }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],
      specializations: publicPorjects?.filters.specializations
        ? publicPorjects?.filters.specializations
            ?.map(({ name, id }) => ({ label: name, id }))
            .sort((a, b) => a.label.localeCompare(b.label))
        : [],

      countries: [],
      skills: [],
      range: { min: publicPorjects?.filters.wages.min, max: publicPorjects?.filters.wages.max },
    } as Marketplace['filters']['settings']
  }

  const updateSearchParams = <T extends object>(values: T) => {
    const combined = new URLSearchParams(window.location.search)

    const queryParams = new URLSearchParams({
      ...Object.fromEntries(combined),
      ...Object.fromEntries(objectToUrlSearchParams(values)),
    })

    const replacedUrl = `${window.location.pathname}?${queryParams.toString()}`
    window.history.replaceState(null, '', replacedUrl)
  }

  const resetData = () => {
    setCurrentPage(1)
    setData([])
  }

  const onFiltersChange: Marketplace['onFiltersChange'] = (values) => {
    resetData()
    updateSearchParams(values)

    setFiltersValues(values)
  }

  const onSort: Marketplace['onSort'] = ({ value }) => {
    resetData()

    updateSearchParams({ orderBy: value })

    setOrderBy((prevState) => ({
      ...prevState,
      direction: value,
    }))
  }

  const isLoading =
    filtersValues.type === 'students'
      ? publicStudentsNetworkStatus === NetworkStatus.loading
      : publicProjectsNetworkStatus === NetworkStatus.loading
  const isRefetching =
    filtersValues.type === 'students'
      ? publicStudentsNetworkStatus === NetworkStatus.setVariables
      : publicProjectsNetworkStatus === NetworkStatus.setVariables

  const hasMore =
    filtersValues.type === 'students'
      ? publicStudents?.paginatorInfo.currentPage !== publicStudents?.paginatorInfo.lastPage
      : publicPorjects?.paginatorInfo.currentPage !== publicPorjects?.paginatorInfo.lastPage

  const formRef = useRef<MarketplaceFormMethods>(null)

  useEffect(() => {
    if (
      filtersValues.type &&
      previousFiltersValues?.type &&
      filtersValues.type !== previousFiltersValues?.type
    ) {
      formRef.current?.reset({
        ...defaultValues,
        type: filtersValues.type,
      })
      updateSearchParams({
        ...defaultValues,
        type: filtersValues.type,
      })
    }
  }, [filtersValues, previousFiltersValues])

  if (!publicStudents) {
    return <></>
  }

  return (
    <div data-module>
      <MetaTagsProvider
        title={t('meta.marketplace.title') || ''}
        metaDescription={t('meta.marketplace.description') || ''}
      >
        <MarketplaceScreenPage
          headerNavigation={navigation}
          footerItems={footer}
          footerBottomItems={footerBottom}
          marketplace={{
            formRef,
            onSort,
            onFiltersChange,
            requestOnLoadMore: () => {
              setIsLoadingMore(true)
              setCurrentPage((prevState) => prevState + 1)
            },
            isLoading,
            isRefetching,
            isLoadingMore,
            hasMore,
            totalItems,
            orderBy: orderBy.direction as 'asc',
            type: filtersValues.type,
            filters: {
              settings: createSettings(filtersValues.type || 'students'),
              defaultValues: initialFiltersValues,
            },
            data,
          }}
        />
      </MetaTagsProvider>
    </div>
  )
}
